
import { computed, nextTick, watch } from "vue"
import echarts from "echarts"

export default {
  props: {
    list: {
      type: Array
    }
  },
  setup() {
    const option = computed(() => {
      return {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          bottom: "0",
          orient: "horizontal",
          textStyle: {
            color: "rgba(255, 255, 255, .75)"
          },
          data: ["4小时以下", "4-6小时", "6-8小时", "8小时以上"]
        },
        series: [
          {
            name: "睡眠时长",
            type: "pie",
            radius: "55%",
            center: ["50%", "45%"],
            data: [
              { value: 139, name: "4小时以下" },
              { value: 1694, name: "4-6小时" },
              { value: 5095, name: "6-8小时" },
              { value: 3331, name: "8小时以上" }
            ],
            label: {
              color: "#fff",
              formatter: (value: { percent: number }) => {
                return value.percent + "%"
              }
            },
            color: ["#18396e", "#1f4a8f", "#275cb1", "#2f6dd2"],
            // color: [
            //   "rgb(107, 250, 185,.84)",
            //   "rgb(107, 204, 249,.72)",
            //   "rgb(250, 203, 87,.72)",
            //   "rgb(255, 66, 84,.72)"
            // ],
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function() {
              return Math.random() * 200
            }
          }
        ]
      }
    })
    let ref: HTMLDivElement
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let echartsDom: echarts.ECharts
    const setRef = (el: HTMLDivElement) => (ref = el)
    nextTick(() => {
      echartsDom = echarts.init(ref)
      echartsDom.setOption(option.value as echarts.EChartOption)
    })
    watch(
      () => option.value,
      (val) => {
        echartsDom?.setOption(val as echarts.EChartOption)
      }
    )
    return { setRef }
  }
}
