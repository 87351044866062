
import { Type } from "../script/table-div"

export default {
  props: ["title", "thead", "list"],
  setup() {
    return {
      Type
    }
  }
}
