export enum Type {
  STRING,
  IMAGE
}

export class DataModel {
  type: Type
  value: string

  constructor(type: Type, value: string) {
    this.type = type
    this.value = value
  }
}
