
import echarts from "echarts"
import { computed, nextTick, watch } from "vue"
import { ECHARTS_GRID } from "@/views/report/script/constant"

export default {
  setup() {
    const option = computed(() => {
      return {
        grid: ECHARTS_GRID,
        xAxis: {
          type: "value",
          min: 0,
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,.12)",
              width: 1,
              type: "solid"
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,.75)" //更改坐标轴文字颜色
            }
          }
        },
        yAxis: {
          type: "category",
          min: 0,
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,.75)" //更改坐标轴文字颜色
            }
          },
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
        },
        series: [
          {
            type: "bar",
            barWidth: 16,
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0],
              color: "#5cabd2"
            },
            data: [320, 302, 341, 374, 390, 450, 420]
          }
        ]
      }
    })
    let ref: HTMLDivElement
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let echartsDom: echarts.ECharts
    const setRef = (el: HTMLDivElement) => (ref = el)
    nextTick(() => {
      echartsDom = echarts.init(ref)
      echartsDom.setOption(option.value as echarts.EChartOption)
    })
    watch(
      () => option.value,
      (val) => {
        echartsDom?.setOption(val as echarts.EChartOption)
      }
    )
    return { setRef }
  }
}
