
import ActiveDetail from "./components/ActiveDetail.vue"
import Quality from "./components/Quality.vue"
import Long from "./components/Long.vue"
// import Age from "./components/Age.vue"
import China from "./components/China.vue"
import TableDiv from "./components/TableDiv.vue"
import { Type, DataModel } from "./script/table-div"
import { SleepDataService } from "@/assets/script/service/sleep-data.service"
import { readonly, ref } from "vue"
import qs from "qs"
import { localGet } from "@/assets/script/utils/storage.util"
import { COUNT_VIEW_MODEL_KEY } from "@/assets/script/constant/config.constant"
import { ExpoConfigViewModel } from "@/assets/script/model/main.model"
import { format } from "@/assets/script/utils/date.util"
import { useRouter } from "vue-router"

function average(value: number[]) {
  let i
  let avg = 0
  let sum = 0
  for (i = 0; i < value.length; i++) {
    sum += value[i]
  }
  avg = sum / value.length
  return avg
}

function regression(xList: number[], yList: number[]) {
  const x = average(xList)
  const y = average(yList)

  let Lxx = 0
  let Lxy = 0
  for (let i = 0; i < xList.length; i++) {
    Lxx += (xList[i] - x) * (xList[i] - x)
    Lxy += (xList[i] - x) * (yList[i] - y)
  }

  const a = Lxy / Lxx
  const b = y - a * x
  return {
    a,
    b
  }
}

export default {
  components: {
    ActiveDetail,
    Quality,
    Long,
    // Age,
    China,
    TableDiv
  },
  setup() {
    const weChatUserList = ref([] as DataModel[][])
    const deviceList = ref([] as DataModel[][])
    const expoConfigViewModel = readonly(
      localGet(COUNT_VIEW_MODEL_KEY, new ExpoConfigViewModel())
    )
    SleepDataService.refreshUserAndDevice().then((result) => {
      weChatUserList.value =
        result.data?.weChatUserList.map((it) => [
          new DataModel(Type.IMAGE, it.headimgurl),
          new DataModel(Type.STRING, it.nickname),
          new DataModel(Type.STRING, it.attentionTime)
        ]) ?? []
      deviceList.value =
        result.data?.deviceList.map((it) => [
          new DataModel(Type.STRING, it.deviceId),
          new DataModel(Type.STRING, it.username)
          // new DataModel(Type.STRING, it.activateTime)
        ]) ?? []
    })

    const day = ref(format(new Date()))
    const time = ref(format(new Date(), "hh:mm"))

    const router = useRouter()

    const p1 = router.currentRoute.value.query.p1 || "" // 获取 phone 参数值，如果没有则默认为空字符串
    const p2 = router.currentRoute.value.query.p2 || "" // 获取 password 参数值，如果没有则默认为空字符串

    // console.log("p1="+p1);
    // console.log("p2="+p2);

    setInterval(() => {
      day.value = format(new Date())
      time.value = format(new Date(), "hh:mm")
    }, 1000)
    return {
      weChatUserList,
      deviceList,
      day,
      time,
      query: qs.stringify(
        { p1: p1, p2: p2 },
        // { p1: expoConfigViewModel.phone, p2: expoConfigViewModel.password },
        {
          arrayFormat: "repeat"
        }
      )
    }
  }
}
